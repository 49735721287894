import React from 'react';

const PublicationTile = ({ day, month, year, title, excerpt, path }) => {
    return (
        <>
            <article>
                <div className="row pb-4">
                    <div className="col-auto pr-0">
                        <div className="date">
                            <span className="day bg-color-light font-weight-semibold text-color-primary text-1">{month}</span>
                            <span className="day text-color-dark font-weight-extra-bold">{day}</span>
                            <span className="day bg-color-light font-weight-semibold text-1">{year}</span>
                        </div>
                    </div>
                    <div className="col pl-1">
                        <h4 className="line-height-3 text-4"><a href={path} target="_blank" className="text-dark">{title}</a></h4>
                        <p className="line-height-5 pr-3 mb-1">{excerpt}</p>
                        <a href={path} target="_blank" className="read-more text-color-dark font-weight-bold text-2">read more <i className="fas fa-chevron-right text-1 ml-1"></i></a>
                    </div>
                </div>
            </article>
        </>
    );
}

export default PublicationTile;